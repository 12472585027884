/* The background backdrop is done inside the div
   The background image is done in the span
*/

*{
    padding: 0;
    margin: 0;
}

.landing_page_wrapper{
margin: 0;
padding: 0;
width: 98.75vw;
height:100vh;
position: relative;
overflow:hidden;
}

.landing_page_wrapper::before, .landing_page_wrapper::after{
    margin: 0;
    padding: 0;
    overflow: hidden
}

/*The curved svg under*/
.Svg{
    z-index: 50;
    width: 110%;
    height: 20vh;
    position: absolute;
    bottom: 0;
    display: block;
    fill: #98bdd8;
    overflow: hidden;
    
}

.Backgrounds  {
    max-width: 100%;
    z-index: 3;
    position: relative;
    background:  url('../../assets/images/pattern.png') repeat top left;
    background-color: rgba(0,0,0,0.3);
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
}

.Backgrounds li {
    list-style: none;
    width: 100%;
    margin: 0;
}

.Backgrounds li span {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    animation: imageAnimations 15s infinite linear 0s;

}

.Backgrounds li span div{
    position: absolute;
    width: 100%;
    height: 100vh;
    animation: backgroundAnimation 7.5s infinite linear 0s;
    overflow: hidden;
}

.Backgrounds li .landing_first {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1;
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right:110vw;
    animation: landingAnimation 15s infinite linear 0s;
    

}

.Backgrounds .landing_text{
    width: 100%;
    height: 300px;
    border-radius: 7px;
    display:flex;
    justify-content: space-around;
    align-items: center;
}

.Backgrounds li .landing_text img{
    border-radius: 8px;
    border: 2px solid #98bdd8;
    transform: translateX(5vw);
    opacity: 0;
    z-index: 50;
    animation: imageAnimation 15s infinite linear 0s;
    max-height:250px;
    max-width: 45vw;
}

.Backgrounds li:nth-child(2) .landing_text img{
    border: transparent;
    width: 55vw;
    max-height: 500px;
}

.Backgrounds li .landing_buttons{
    width: 500px;
    margin: 50px auto 70px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transform: translateY(1vw);
    z-index: 0;
    opacity: 1;
    animation: buttonsAnimation 15s infinite linear 0s; 
}



.landing_buttons h4{
height: 50px;
width: 150px;
text-align: center;
text-transform: uppercase;
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
color: white;
background: #98bdd8;
cursor: pointer;
position: relative;
z-index: 0;
}

.Backgrounds li:nth-child(2) .landing_buttons h4{
    position: relative;
    z-index: 100;
    transform: translateY(-4vw);
    }
.Backgrounds li:nth-child(3) .landing_buttons h4{
        z-index: 0;
        }
.Backgrounds li:nth-child(2) .landing_buttons h4:hover,
.landing_buttons h4:hover{
    background: white;
    color: #98bdd8

}

.Backgrounds li div h2 {
    font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
    font-size: 27px;
    width: 400px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 50;
    opacity: 0;
    border-radius: 7px;
    padding: 20px;
    margin-left: 10px;
    
    transform: translateX(-4vw);
    animation: textAnimations 15s infinite linear 0s; 
}

.Backgrounds li:nth-child(1) span{
    background-image: url('../../assets/new\ image/laser-cut-gate.jpg');
}

.Backgrounds li:nth-child(2) span {
    background-image: url('../../assets/new\ image/photo1.jpg');
    animation-delay: 7.5s;
}
.Backgrounds li:nth-child(2) .landing_first,
.Backgrounds li:nth-child(2)  div h2, 
.Backgrounds li:nth-child(2) .landing_buttons,
.Backgrounds li:nth-child(2) .landing_text img{
    animation-delay:7.5s;
}

@keyframes landingAnimation {
    0% {
        right: 0;
    }
    1%{
       right: 0
    }

    50%{
        right: 0;
    }
    51%{
        right: 110vw;
    }
    55%{
        right: 110vw
    }
}

@keyframes imageAnimations {
    0% {
        opacity: 0;
        transform: translateX(0);
        animation-timing-function: ease-in;
    }

    9% {
        opacity: 1;
        animation-timing-function: ease-out;
        transform: scale(1.07);
    }
    22% {
        opacity: 1;
        transform: scale(1.1) translateX(0);
    }
    50%{
        opacity: 1;
    }
    59%{
        opacity:0;
        transform: scale(1.1) translateX(0);
    }
  
   

    100% {
        opacity: 0;
        transform: translateX(-9999px);
    }

}

@keyframes textAnimations {
    0% {
        opacity: 0;;
    }
    4%{
        opacity: 0
    }

    8% {
        opacity: 1;
        transform: translate(0);
      
    }
    9%{
        background-color: transparent;
    }
    13% {
        opacity: 1;
        transform: translateY(0);
        background-color: #98bdd8c7;
    }
    41%{
        opacity: 1;
        transform: scale(1)
    }
    43% {
        opacity: 0;
        transform: translateX(-3vw);
    }
    100% {
        opacity: 0;
    }
}

@keyframes imageAnimation {
    0% {
        opacity: 0;;
    }
   
    7%{
        opacity: 0;
      
    }
    10% {
        opacity: 1;
        transform: translate(0);
        z-index: 50;
    }
    47%{
        opacity: 1;
        transform: scale(1)
    }
    50% {
        opacity: 0;
        transform: translateX(3vw);
    }
    100% {
        opacity: 0;
        transform: translateY(110vh);
    }
}

@keyframes buttonsAnimation {
    0% {
       opacity: 0 
    }
   
    11%{
        
        opacity: 0;
        
    }
    12% {
        opacity: 1;
       transform: translateY(0);
        
    }
    40%{
        
        transform: translateY(0);
        opacity: 1;
    }
    43% {
        
        opacity: 0;
        transform: translateY(3vw);
      
    }
    100% {
       
        opacity: 0;
        transform: translateY(110vh);
    }
}

@keyframes backgroundAnimation{
    0%{
        background-color: rgba(0,0,0,0);
    }
    32%{
        background-color: rgba(0,0,0,0);
    }

    40%{
        background-color: rgba(0,0,0,0.7);
    }

    90%{
        background-color: rgba(0,0,0,0.7);
    }

    94%{
        background-color: rgba(0,0,0,0);
    }

    100%{
        background-color: rgba(0,0,0,0);
    }
}


@media screen and (min-width: 600px){
    .Backgrounds, .Backgrounds::after {
        margin: 0 auto;
        height: 100vh;
    }

    .Backgrounds li div h3 {
        font-size: 40px;
    }
    
}

@media screen and (max-width: 1030px){
    .landing_page_wrapper{
        width: 100vw;
    }
    .Svg{
        Transform: translateY(.5vh)
    }
    .Backgrounds .landing_text{
        justify-content: space-between;
    }

    .Backgrounds li div h3{
        width: 400px;
        margin-left: 1vw;
        font-size: 32px;
        height: 60%;
    }
    .Backgrounds li .landing_text img{
        margin-right: 1vw;
    }
}

@media screen and (max-width: 760px){
    .Backgrounds li div h2{
        margin-top: 5vh;
        width: 60vw;
        font-size: 6vw;
        font-weight: normal;
    }
    .Backgrounds li span {
        height: 93vh;
    }

    .Backgrounds li .landing_first{
        transform: translateY(-35px);
        height: 93vh;
    }
    
    .Backgrounds li div h3{
        width: 45vw;
        margin-left: 1vw;
        font-size: 20px;
        letter-spacing: 3px;
        font-weight: 400;
        height: 60%;
    }
    .Backgrounds li .landing_text img{
        margin-right: 1vw;
        max-height: 200px;
        max-width: 75vw;
    }

    .Backgrounds li:nth-child(5) .landing_text img{
        max-height: 500px;
        width: 85vw;
    }
}

@media screen and (max-width: 585px){
    
    .Backgrounds li div h3{
        width: 40vw;
        margin-left: 1vw;
        font-size: 20px;
        letter-spacing: 3px;
        font-weight: 400;
        height: 50%;
    }
    .Backgrounds li .landing_text img{
        margin-right: 1vw;
        max-width: 75vw;
    }

    .Backgrounds li:nth-child(5) .landing_text img{
        max-height: 500px;
        width: 85vw;
    }

    .Backgrounds li .landing_buttons{
        width: 100%;
    }

    .landing_buttons h4{
        height: 40px;
        width: 130px;
    }
}

@media screen and (max-device-width: 1000px){
    .Backgrounds .landing_first{
        top: 65px;
    }
    .Backgrounds .landing_text{
        height: 70vh;
        justify-content: space-around;
        align-items: space-around;
        flex-direction: column;
    }

    .Backgrounds .landing_text h3{
        width: 70vw;
        height: auto;
        padding: 20px;
    }

   .landing_page_wrapper .Backgrounds li .landing_text img{
        max-width: 75vw ;
    }

    .Backgrounds li .landing_buttons{
        margin: 1px auto 70px auto;
    }

}

/* This targets phones with long screens*/
@media screen and (max-aspect-ratio:16/9) and (max-width:630px){
    .landing_page_wrapper{
        height: 600px;
        width: 100%
    }
    .Svg{
        height: 90px;
        width: 120vw;
        transform: translateY(0.5vh);
    }
    .Backgrounds, .Backgrounds::after{
        height: 100%;
    }
    .Backgrounds li span {
        max-height: 100%;
    }

    .Backgrounds li span div {
        max-height: 100%;
    }

    .Backgrounds li .landing_first{
        height: 100%;
    }
}

/* Smaller phones needs some attention */
@media screen and (max-width:325px){
    .landing_page_wrapper{
        height: 100vh
    }
}

