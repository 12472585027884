*::before, *::after{
    margin: 0;
    padding: 0;
}

.app_top_wrapper{
    margin: 0;
    padding: 0;
    background-color: rgb(242, 250, 252);
}
.app_wrapper{
    width: 100%;
    margin:0 auto ;

}

@media screen and (min-width: 1000px){
    .app_wrapper{
        width: 85%;
    }
}
