.AdminWWD {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: space-around;
    margin-top: 20px; 
 }

 .AdminWWD .edit_bar{
     width: 800px;
     height: 250px;
     background-color: rgb(44, 40, 49);
     position: fixed;
     right: 20%;
     top: 20%;
     border-radius: 7px;
     display: flex;
     flex-direction: column;
 }

.AdminWWD .edit_bar .input_fields{
    min-height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transform: translateX(50px)
}

.AdminWWD .edit_bar input{
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
}

.AdminWWD .edit_bar .input_controls{
    min-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminWWD .edit_bar button{
    height: 30px;
    width: 70px;
    border-radius: 4px;
    margin: 20px;
}
 
 .AdminWWD .sample{
    width: 100%;
 }
 
 .AdminWWD .sample table{
     table-layout: fixed;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 2px solid white;
 }

 .AdminWWD .table_row td{
    padding-top: 20px;
    text-align:  center;
    color: white;
 }

 .AdminWWD th{
    color: white;
    padding-bottom: 10px;
 }

 .AdminWWD .table_row button{
     padding: 2px 7px;
     border-radius: 2px;
 }

 
 .AdminWWD .sample h5{
     width: 40%;
     text-align: justify;
     margin :auto 10px;
     font-weight: 300;
     font-size: 20px;
     color: rgb(240, 235, 235);
 }
 
 .AdminWWD form{
     margin-top: 20px;
 }
 
 .AdminWWD form div{
    display: flex;
    justify-content: space-around;
    align-items: center;
 
 }
 
 .AdminWWD form div input{
     border-radius: 5px;
     height: 25px;
     text-align: center;
 }

 .AdminWWD  textarea{
     text-align: center;
     border-radius: 5px;
 }
 .AdminWWD form .submit{
     display: block;
     margin: 20px auto;
     width: 100px;
     height: 40px;
     border-radius: 5px;
     font-size: 18px;
 }
.file_input{
     color: white;
 }
