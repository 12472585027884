.navigation_items{
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.navigation_items div{
  color: white;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  text-decoration: none;
}

.navigation_items div:hover{
background-color: white;
color: #98bdd8;
cursor: pointer;
}


.navigation_items .navlink{
  text-decoration: none;
}
.navigation_items div h3{
  text-decoration: none;
  text-align: center;
}

.navigation_items .active-class{
  background-color: white;
  border-radius: 10px;
  color: #98bdd8;
  cursor: pointer;
}

.navigation_items .active-class h3{
  color: #98bdd8;
}


@media screen and (max-width:800px){
  .navigation_items {
     padding-top: 45px;
     flex-direction: column;
     box-sizing: border-box;
     width: 100%;
     height: 300px;
     align-items: flex-start;
     padding-left: 30px;
    
  }
}
