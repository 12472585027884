.input_wrapper {
    width: 100%;
    box-sizing: border-box;
}

.input_element {
        width: 100%;
        display: block;
        padding: 7px 10px;
        border: 1px solid #bbb;
        border-radius: 4px;
        background-color: white;
        box-sizing: border-box;
        margin: 8px 0;
}

.textarea {
        width: 100%;
        display: block;
        padding: 7px 10px;
        border: 1px solid #bbb;
        background-color: white;
        box-sizing: border-box;
        margin: 8px 0;
}

.input_element:focus,
.input_element:hover{
    outline: none;
    background-color: #ccc;
}