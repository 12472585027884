.side_drawer{
    position: fixed;
    width: 300px;
    height: 100vh;
    z-index: 100;
    background-color: #98bdd8ec;
    top: 0;
    left: 0;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgb(231, 230, 230);
    transition: all 0.3s ease-in-out;
    padding-bottom: 30px;
}


.open {
    transform: translateX(0);
}

.close {
    transform: translateX(-100%);
}