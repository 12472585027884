
.AboutUs{
    width: 100%;
    background-color: #98bdd8;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative
}

.AboutUs .title {
    border-bottom: solid  1px white;
    display: inline-block;
    margin: 80px auto 30px auto;

}

.AboutUs .title h1 {
    color: white;
    font-family: 'Times New Roman', Times, serif ;
}

.AboutUs .aboutus_body{
    position: relative;
}

.AboutUs .aboutus_body .description{
    width: 35vw;
    position: absolute;
    z-index: 2;
    left: 13vw;
    top: 1vw;
    text-align: center;
    transition: all ease-in 0.8ss;
    opacity: 0;
    transform: translateY(-30px)
}

.AboutUs .aboutus_body h2{
    font-size: 2.4vw ;
    color: white;
    
}


.AboutUs  img{
    transform: translateX(2vw);
    opacity: 0;
    transition: all ease-in .9s;
    width: 100vw;
    height: 100%;
    animation: aboutusImage 15s infinite linear 0s;
}

.AboutUs .Svg{
    fill: rgb(230, 245, 248)
}


.AboutUs .aboutus_body .show{
    opacity: 1;
    transform: translate(0)
}

@media screen and (max-width:780px){

    .AboutUs img{
        width: 124vw;
        margin-top: 17vh;
    }

    .AboutUs .Svg{
        transform: translateY(4vh);
    }

    .AboutUs{
        padding-top: 0;
        margin-top: 0;
        height: 62vh
    }
    .AboutUs .title{
        margin-top: 10px;
    }

    .AboutUs .aboutus_body .description{
        top: 0vh;
        left: 5vw;
        width: 60vw;
    }

    .AboutUs .aboutus_body h2{
        font-size: 5vw ;
        
    }


}

@keyframes aboutusImage {
    0% {
        transform: translateY(0vh);
    }

    3% {
        transform: translateY(0vh);
    }

    50%{
       transform: translateY(10vh);
    }

    97%{
        transform: translateY(0vh);
     }
    
    100%{
        transform: translateY(0vh);
    }
}