.contactUs_title{
    padding-top: 85px;
    width: 50%;
    margin: auto;
}

.contact_form_wrapper{
    width: 100%;
    margin: 24px 0;
}

.contact_address{
    margin: 40px;
}

.contact_address h2 {
    margin-left: 100px;
}

.contact_address *{
    color: grey;
}

.contact_address .inner_contact_address {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.contact_address .inner_contact_address .second-contact{
    width: 350px;
}

.inner_contact_address .second-contact:nth-child(2){
    margin-top: 40px;
}

.contact_address a img{
    height: 50px;
    width: 50px;
}

.contact_address a {
    width: 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin:30px auto;
}
.contact_address h2{
    color: rgb(71, 83, 87)
}

.contact_address h3 i {
    font-size: 30px;
    margin: 1px 15px 1px 0px;

}

.contact_address h3 {
    text-align: center;
}

.contact_form_wrapper div.name {
    background-color: rgba(228, 61, 139,0.7);;
    width: 50%;
    margin: 10px auto;
    height: 43px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    
}
.contact_form_wrapper div.name h4 {
    text-transform: uppercase;
    color: white;
    font-size: 18px;
}
.contact_form_wrapper div.name h4 a{
    text-decoration: none;
    color: white;
}

.contact_form {
    margin:  8px;
    border-radius: 7px;
    padding-bottom: 1px;
    box-shadow: 4px 4px 6px rgb(177, 175, 175);
}


.contact_form form button{
    display: block;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 20px;
    width: 100px;
    height: 32px;
    color: white;
    background-color:  rgba(44, 142, 233, 0.829);;
    
}

.social_media_wrapper{
    width: 99%;
    display: flex;
    justify-content: space-around;
}

@media screen and (min-width: 600px) {
    .contact_form_wrapper{
        width: 60%;
        margin: 24px auto;
    }
}