.Request {
    margin: 20px auto;
    width: 85%;  
}

.Request .message{
    border: 1px solid rgba(10, 97, 124,1);
    width: 350px;
    margin: auto;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background: rgba(255, 255, 255, 0.76);
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
}

.Request .message h3{
    padding: 40px 10px 20px 10px;
    text-align: center;
    color: rgba(10, 97, 124,1);
    
}

.Request .message button{
    padding: 10px 30px;
    margin: 20px;
    text-align: center;
}

.Request .image{
    padding-top: 70px;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Request .image img{
    max-width: 100%;
    border-radius: 3px;
}

.Request .controls {
    margin: 60px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.Request .controls a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background: rgb(46, 151, 46);
    border-radius: 7px;
    margin: 20px 10px;
    text-decoration: none;
    transition: 0.3s all ease-in;
}

.Request .controls .email {
    background-color: rgb(255, 82, 82) ;
}

.Request .controls img{
    width: 70px;
    margin: 0 5px;
}

.Request .controls h3{
    width: 250px;
    color: white;
    text-align: center;
    padding: 23px 0;
    cursor: pointer;
    transition: 0.3s all ease-in;
}



.Request .controls a:hover{
    background: white;
    border: 1px solid rgb(46, 151, 46);
}

.Request .controls a:hover h3{
    color: rgb(46, 151, 46)
}

.Request .controls .email:hover{
    background: white;
    border: 1px solid rgb(255, 82, 82);
}

.Request .controls .email:hover h3{
    color: rgb(255, 82, 82);
}


.Request .form form {
    width: 505px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 15px grey;
    padding: 20px;
    border-radius: 8px;

}

.Request .form form input{
    margin: 8px;
    border: 1px solid rgba(128, 128, 128, 0.589);
    width: 500px;
    padding-left: 10px;
    height: 30px;
    font-size: 14px;
    border-radius: 3px;

}

.Request .form form div input{
    width: 100px;
    color: white;
    background: rgb(255, 82, 82);
}

@media screen and (max-width:601px){
    .Request .image{
        width: 100%;
    }

    .Request .form form{
        width: 90%;
    }

    .Request .form form input{
        width: 88%;
    }
}
