.Whyuswrap{
    background: rgb(230, 245, 248);
    box-sizing: border-box;
    width: 100%;
}

.Whyuswrap .wrap{
    box-sizing: border-box;
    width: 100%;
    margin-top: -1px;
    padding: 40px;;
    
    /**/
}

.whyus{
    display: flex;
    flex-wrap: wrap;
}
 .wrap .title h2{
     border-bottom: 2px solid black;
     text-align: center;;
     padding-bottom: 5px;
 }
.whyus_inner {
    margin: auto;
}

.title_why_us{
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.briefcase{
    transform: translateX(-20%) scale(0.9);
    opacity: 0;
    transition: all 0.5s ease-out;
}

.heart{
    transform: translateY(20%);
    opacity:0;
    transition: all 1.5s ease-out;
}

.user_shield{
    transform: translateX(15%) scale(0.9);
    opacity: 0;
    transition: all 0.5s ease-out;
}

.delivery{
    transform: scale(0.95);
    opacity: 0;
    transition: all 0.7s ease-out
}

.Whyuswrap .svgdiv{
    overflow: hidden;
}
.Whyuswrap .Svg{
    position: relative;
    width: 105vw;
    fill: rgb(242, 250, 252);
}


.show{
    transform: translateY(0) scale(1);
    opacity: 1;
}