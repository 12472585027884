.completed-projects{
    padding-top: 50px;
}


.ourWork_images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid rgb(206, 206, 206);;
}

.ourWork_title {
   width: 170px;
   margin: 60px auto 0px auto;
   border-bottom: 2px solid rgb(39, 39, 39);
   
}

.ourWork_title h2{
   text-align: center;
   color: rgb(39, 39, 39);
   font-size: 32px;
   font-weight: bold;
}

.ourWork_message {
   width: 90%;
   margin: auto;
}

.ourWork_message h2 {
   margin-top: 20px;
   text-align: center;
   font-weight: 600;
   font-size: 23px;
   color: rgb(70, 69, 69);
}

.ourWork_message .others{
   margin-top: 60px;
}

.ourWork_message h3{
   text-align: center;
   font-size: 19px;
   font-weight: 400;
   color: rgb(160, 160, 160);
   margin-top: 5px;
}


@media screen and (min-width: 600px) {

   .whatWeDo_wrapper{
       width: 100%;
   }

   .ourWork_images {
       margin: 30px 0;
       width: 100%;
       display: flex;
       flex-wrap: wrap;
       flex-direction: row;
       justify-content: space-around;
  }
}