.ViewMore {
    width: 95%;
    margin: auto;
}

.ViewMore .original-image{
    border: 2px solid rgba(10, 97, 124,0.76);
    border-radius: 8px;
    position: fixed;
    z-index: 20;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    transition: 0.35s ease-in all;
    background: rgba(0, 0, 0, 0.452);
    min-width: 300px;
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;

}

.ViewMore .original-image img{
    max-height: 550px;
    margin-bottom: -5px;
    border-radius: 3px;
   
}


.ViewMore .original-image .controls{
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 85%;
    top: 50%;
}

.ViewMore .original-image .icon{
    background: rgba(255, 255, 255, 0.445);
    padding: 0 10px;
    color:  rgba(255, 0, 0, 0.438);
    font-size: 30px;
    cursor: pointer;
    border-radius: 3px;
    transition: 0.3s all ease-out;
}

.ViewMore .original-image .controls .icon:hover{
    background:  rgba(255, 0, 0, 0.644);
    padding: 0 10px;
    color: white;
    font-size: 40px;
    cursor: pointer;
}

.ViewMore .original-image .cancel .icon {
    position: absolute;
    margin: 10px;
    top : 0;
    right : 0;
    font-size : 30px;
    color : rgba(255, 0, 0, 0.465);

}

.ViewMore .original-image .cancel .icon:hover{
    background:  rgba(255, 0, 0, 0.616);
    padding: 0 10px;
    color: white;
    font-size: 40px;
    cursor: pointer;
}
.ViewMore .original-image .request {
    position: absolute;
    margin: 10px auto 10px auto;
    bottom : 0;

}

.ViewMore .original-image .request h3{
    font-size : 25px;
    padding: 5px 12px;
    background-color : rgba(255, 0, 0, 0.575);
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.ViewMore .original-image .request h3:hover{
    color:  red;
    background:white
}

.ViewMore .texts{
    margin-top: 70px;
    text-align: center;
    padding-top: 40px;

}

.ViewMore .texts h1 {
    margin: 30px auto 40px auto;
    border-bottom: 3px solid #98bdd8;
    border-radius: 2px;
    width: 400px;
}

.ViewMore .texts h3 {
    margin-bottom: 10px;
    color: rgb(99, 100, 100);
    font-family: 'Alegreya';
}

.ViewMore .row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
}

.ViewMore .row .image{
   width: 300px;
   height: 240px;
   margin: 10px 4px;
   overflow: hidden;
   position: relative;
   border-top-right-radius: 6px;
   border-top-left-radius: 6px;
}

.ViewMore .row .image img{
    width: 100%;
    transition: all 0.4s ease-in;
}

.ViewMore .row .image .smallBackdrop{
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgb(15, 21, 29);
    z-index: 5;
    opacity: 0;
    transition: all 0.4s ease-in;
}

.ViewMore .row .controls {
    position: absolute;
    z-index: 10;
    bottom: 40%;
    width: 100%;
    height: 40px;
    display: flex;
    transition: all 0.25s ease-in;
    border: 1px solid #98bdd8;
    border-radius: 4px;
    background: white;
    opacity: 0;
    
}

.ViewMore .row .image:hover .controls{
    opacity: 1;
}
.ViewMore .row .image:hover .smallBackdrop{
    opacity: 0.4
}
.ViewMore .row .image:hover img{
    transform: scale(1.1);
}

.ViewMore .controls .zoomIcon{
    height: 100%;
    width: 50%;
    border-right: 2px solid #98bdd8;
    display: flex; 
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in
}
.ViewMore .controls .zoomIcon img{
    height: 100%;
    width: 40%;
    margin: auto;
    transition: all 0.4s ease-in
}

.ViewMore .row .controls .zoomIcon:hover{
    background:#98bdd870 ;
    cursor: pointer;
    
}
.ViewMore .row .controls .zoomIcon:hover img{
    transform: scale(1.3);
}


.ViewMore .row .controls .link{
    width: 50%;
    border-left: 1px solid #98bdd8; 
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #98bdd8;
    transition: all 0.3s ease-in;
    font-size: 16px;
}

.ViewMore .row .controls .link:hover{
    background: #98bdd8;
    color: white;
    cursor: pointer;
    font-size: 18px ;
}

.ViewMore .outerSpinner{
    width: 100%;
    height: 80vh;
    display: flex ;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}


.ViewMore .outerSpinner h2{
    margin: 30px;
    font-size: 4vw;
    color: #98bdd8
}

.ViewMore .row .video iframe{
    width: 560px;
    height: 420px;
}
/*For Tablets */
@media screen and (max-width:1030px) and (min-width: 500px) and (max-device-aspect-ratio: 1/1 ){
  
    .ViewMore .row .image .controls{
        opacity: 1;
        bottom: 0px;
        border-bottom: 0px ;
    }
    .ViewMore .original-image img{
        max-width: 100vw;
    }
}



/*For phones */
  @media screen and (max-width: 501px) {
    .ViewMore {
        width: 100vw;
        box-sizing: border-box;
        border: 0.5px solid  #98bdd8;
        ;
    }

    .ViewMore .original-image img{
        max-width: 100vw;
    }

    .ViewMore .row .image{
        width: 90vw;
        min-height: 200px;
        border-bottom: rgb(184, 181, 181) 1px solid;
    }
    
    .ViewMore .row .image .controls{
        opacity: 1;
        bottom: 0px;
        border-bottom: 0px ;
        width: 50%;
    }

    .ViewMore .row .controls .zoomIcon{
        display: none;
    }

    .ViewMore .row .controls .link{
        width: 100%;
    }
    .ViewMore .texts{
        margin-top: 0px;
    }
    .ViewMore .texts h1{
        Width: 80vw;
        font-size: 25px;
    }

    .ViewMore .row .video iframe{
        width: 90vw; 
        height: 360px;
    }

   }
