.AdminWWA {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content:flex-start;
   align-items: space-around;
   margin-top: 30px; 
}

.AdminWWA .sample{
    display: flex;
    flex-direction: row;
    align-items: space-around;
    justify-content: space-around;
    padding-bottom: 30px;
    border-bottom: 1px solid white;
    min-height: 270px;

}

.AdminWWA .sample h5{
    width: 40%;
    text-align: justify;
    margin :auto 10px;
    font-weight: 300;
    font-size: 20px;
    color: rgb(240, 235, 235);
}

.AdminWWA form{
    margin-top: 20px;
}

.AdminWWA form div{
   display: flex;
   justify-content: space-around;
   align-items: center;

}

.AdminWWA form div textarea{
    border-radius: 5px;
}
.AdminWWA form .submit{
    display: block;
    margin: 20px auto;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 18px;
}

@media screen and (max-height:600px) {
    .AdminWWA .sample {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .AdminWWA .sample h5{
        width: 80%;
    }

    .AdminWWA .sample img{
        min-width: 70%;
        margin: 20px;
    }
}
