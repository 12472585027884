
.toolbar_wrapper{
    width: 100vw;
    overflow: hidden;
}
.sec_nav{
    position: fixed;
    top: 0px;
    height: 30px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 2500;
    

}

.sec_nav *{
    color: grey;
}

.sec_nav a{
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
}

.sec_nav a h3 {
    margin: 4px;
}

.sec_nav a img{
    width: 20px;
    height: 20px;
}

.toolbar {
    position: fixed;
    top: 30px;
    right: 0;
    left: 0;
    margin: 0 ;
    padding:0;
    z-index: 2500;
    width: 100vw;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #98bdd8;
    overflow: hidden;
}

.toolbar nav {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.toolbar .inner-nav{
    display: none;
}

@media screen and (max-width:800px){
   .toolbar{
       justify-content: space-between;
       top: 0px;
       position: fixed;
   }

  .toolbar nav{
      display: none; 
      
   }

   .toolbar_wrapper .sec_nav{
       display: none;
   }

   .toolbar .inner-nav{
    display: flex;
    height: 100%;
    width: 60%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    
}

.toolbar .inner-nav .whatsapp{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 52%;
    margin-left: 0px;
    box-sizing: border-box;
    justify-content: space-around;
    margin-left: 2px;
}

.toolbar .inner-nav .whatsapp a{
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    
}

.toolbar .inner-nav .whatsapp a:nth-child(1){
    padding-bottom: 2px;
    border-bottom: 1px solid white;
}

.toolbar .inner-nav .email{
    border-left: 1px solid white;
    box-sizing: border-box;
    margin-left: 10px;
    width: 50%;
    height: 100%;
    padding-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toolbar .inner-nav .email a{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
}
.toolbar .inner-nav .email a h3{
    font-size: 2.4vw;
}

.toolbar .inner-nav a img{
   height: 20px;
   width: 20px;
   display: inline;
}

.toolbar .inner-nav h3{
    color: rgb(255, 251, 251);
    font-size: 4vw;
    text-decoration: none;
}

}

@media screen and (max-width: 800px) and (min-width: 550px){
   .toolbar .inner-nav .whatsapp a h3{
        font-size: 3vw;
    }

    .toolbar .inner-nav .whatsapp {
        width: 37%
    }
}