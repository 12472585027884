.MoreWrought {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: space-around;
    margin-top: 20px; 
 }
 .MoreWrought .texts{
     width: 100%;
     margin: 1px auto  50px ;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }

 .MoreWrought .texts h1{
     color: white;
     text-transform: capitalize;

 }

 .MoreWrought .texts p{
     color: white;
     margin: 30px;
 }
 
 .MoreWrought .sample{
    width: 100%;
 }
 
 .MoreWrought .sample table{
     table-layout: fixed;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 2px solid white;
 }

 .MoreWrought .table_row td{
    padding-top: 20px;
    text-align:  center;
    color: white;
 }

 .MoreWrought th{
    color: white;
    padding-bottom: 10px;
 }

 .MoreWrought .table_row button{
     padding: 2px 7px;
     border-radius: 2px;
 }

 
 .MoreWrought .sample h5{
     width: 40%;
     text-align: justify;
     margin :auto 10px;
     font-weight: 300;
     font-size: 20px;
     color: rgb(240, 235, 235);
 }
 
 .MoreWrought form{
     margin-top: 20px;
 }
 
 .MoreWrought form div{
    display: flex;
    justify-content: space-around;
    align-items: center;
 
 }
 
 .MoreWrought form div input{
     border-radius: 5px;
     height: 25px;
     text-align: center;
 }

 .MoreWrought  textarea{
     text-align: center;
     border-radius: 5px;
 }
 .MoreWrought form .submit{
     display: block;
     margin: 20px auto;
     width: 100px;
     height: 40px;
     border-radius: 5px;
     font-size: 18px;
 }
.file_input{
     color: white;
 }
