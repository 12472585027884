.HamburgerOuter {
    width: 42px;
    height: 60%;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-right: 2%;
    border: 1px solid rgba(3, 68, 105, 1);
    border-radius: 2px;
    background-color: rgba(3, 68, 105, 0.25);
}
.HamburgerMenu {
    background-color: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}

.HamburgerMenu div{
    width: 70%;
    height: 2px;
    background-color: white;
    transition: all 0.40s ease-out;
    border-radius:  25%;
    
}

.RotateTop {
    transform-origin: 100%;
    transform: rotate(-40deg) translate(-3px, -1px);
}

.RotateBottom {
    transform: translate(0,-10px) rotate(399deg)
}

.MoveLeft {
    transform: translateX(200px);
    z-index: 200;
    background: white;
}

@media (min-width:800px){
    .HamburgerOuter  {
        display: none;
    }
}