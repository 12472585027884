.Admin{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #201b29;
}

.Admin .right{
    width: 80%;
    height: 100%;
    background: #160c2c;
    margin-top: 47px;
    transform: translateX(25%);
    margin-top: 70px;
}

.Admin .left{
    width: 20%;
    height: 100%;
    margin-top: 90px;
    background: #110922;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
}

.Admin .left button{
    margin: 5px 10px 0 0;
    width: 99%;
    height: 40px;
    border-radius: 5px;
    text-align: left;
    padding-left: 10px;
    
}

.Admin .left .active{
    background-color: rgb(140, 173, 243);
    color: white;
}

