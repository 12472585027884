.logo {
   height: 100%;
   transition: all 0.35s ease-in-out;
   
}
.logo img {
    height: 100%;
}

@media (max-width:800px){
    .logo{
        margin-left: 1%;
    }

    .logo img{
        height: 60%;
        margin-top: 10px;
    }

    .logo.shift{
        margin-left: 20%
    }
}