.ourWork_wrapper{
 min-width: 90vw;
 min-height: 250px;
 width: 100%;
 position: relative;
 margin: 20px auto;
 transform: translateY(60px);
 opacity: 0;
 border: 1px solid #98bdd8;
 border-top-left-radius: 4%;
 border-top-right-radius: 4%;
}

.ourWork_wrapper.active {
    animation: slideInAnimation 1s ease-in-out 1 forwards;
}


.ourWork_image img {
    width: 100%;
    min-width: 300px;
    max-height: 430px;
    border-top-left-radius: 4%;
    border-top-right-radius: 4%;
}

.ourWork_text {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color:  #98bdd8;
}
.ourWork_wrapper .at_hover{
    display: none
}


.ourWork_wrapper .at_hover h3{
    color: white;
    background-color: #98bdd8;
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
}

.ourWork_wrapper .at_hover h3:hover{
    background: white ;
    color: #98bdd8;
    border: 2px solid #98bdd8;;
}

.ourWork_text h2 {
    font-weight: 500;
    font-size: 20px;
    border-radius: 7%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 7px 10px;
    
}

.ourWork_text h3  {
    background-color: white;
    color:#98bdd8;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    height: 44px;
    width: 120px;
    text-align: center;
    
}

.ourWork_text h3 a {
    color: white;
    text-decoration: none;
}

@keyframes slideInAnimation {
    0%{
        opacity: 0;
        transform: translateY(50px)
    }

    70%{
        opacity: 1;
        transform: translateY(7px)
    }
    90%{
        opacity: 1;
        transform: translateY(-2px)
    }

    100%{
        opacity: 1;
        transform: translateY(0)
    }
}

@media screen and (min-width: 600px){

    
.ourWork_wrapper:hover .at_hover{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.205);
}

.ourWork_wrapper:hover div img{
    transition: all 0.3s ease-in;
    transform: scale(1.1);
}

    .ourWork_wrapper{
        min-width: 300px;
        min-height: 300px;
        width: 300px;
        height: 300px;
        margin-top: 30px;
        border-radius: 4%;
        overflow: hidden;
       }

    .ourWork_image img {
        width: 100%;
        }
        
        .ourWork_wrapper .ourWork_text h3 {
            display: none;
        }
}


