.layout, .layout::after, .layout::before{
    margin: 0;
    padding: 0;
}

.layout .fixed_components{
    position: fixed;
    z-index: 100;
    width: 100%;
}

.children{
    margin: 0;
    padding: 0;
    width: 100%;
}

.footer{
    width: 100%;
    height: 150px;
    background-color: #444;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer h4{
    text-align: center;
    font-weight: 300;
    font-size: 18px;
}