.why_wrapper{
   max-width: 300px;
   height: 330px;
   margin: 50px 10px 0 10px;
   text-align: center;
   border-radius: 10px;
   box-shadow: 3px 3px 5px #bbb
}

.why_wrapper .first{
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.why_wrapper .second{
    height: 190px;
}


.why_wrapper h4 {
    color: #444;
    font-weight: 500;
    font-size: 17px;
    padding: 30px;
}

.why_wrapper h3 {
    font-weight: 700;
    font-size: 20px;
}

@media screen and (min-width:500px){
    .why_wrapper{
        width: 370px;
    }
}

.animate{
   animation: love 1.2s infinite ease-in-out 0s;
}

@keyframes love {
    0%{
        transform: scale(0.9);
    }

    50%{
        transform: scale(1.3);
    }

    100%{
        transform: scale(0.9);
    }
}