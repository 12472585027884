.Spinner11 {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.Spinner11 h3 {
    color: white;
    margin: 10px;
}